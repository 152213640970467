<template>
	<div id="warper">
		<div class="box">
			<el-image :src="require('@/assets/img/titie.png')" style="width: 100%;"></el-image>
			<div class="center">
				<div class="title">
					<img src="../../assets/img/riFill-fire-fill.png" style="width:30px;height:30px;" />
					<div class="title_text">招考项目</div>
					<img src="../../assets/img/circular.png"
						style="width:18px;height:18px;position: absolute;left: 2.5%;top: 0px;z-index: 1;" />
				</div>
				<div class="project">
					<div class="trainingProgram" @click="goto('/signup', {id: item.id}, 1)" v-for="item in list"
						:key="item.id">
						<div class="trainingProgram_title">
							<el-image fit="fill" :src="defimgurl+item.pic"></el-image>
						</div>
						<div class="trainingProgram_center">
							<div class="title">{{item.name}}</div>
							<div
								style="display: flex;justify-content: space-around;padding-top: 23px;align-items: center;">
								<div>
								</div>
								<div class="signup">立即报名</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		ExamPageList
	} from "@/api/user";
	import global from "@/assets/js/globalconfig";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				list: [],
				type: 2
			};
		},
		created() {
			this.getExamList()
		},

		methods: {
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			getExamList() {
				var _this = this;
				ExamPageList({}).then((res) => {
					if (res.success) {
						_this.list = res.data.rows;
					}
				})
			}
		},
	};
</script>


<style lang="scss" scoped>
	.box {
		width: 100%;
		min-height: 100%;

		.center {
			width: 90%;
			margin-left: 5%;

			.title {
				display: flex;
				align-items: center;
				margin-top: 19px;
				position: relative;

				.title_text {
					margin-left: 18px;
					color: #101010;
					font-size: 28px;
					font-weight: bold;
					font-family: PMZDBiaoTi-regular;

					z-index: 9;
					background: transparent;
				}
			}

			.project {
				margin-top: 29px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				.trainingProgram {
					width: 270px;
					line-height: 20px;
					border-radius: 8px;
					margin: 10px;
					background-color: #fff;

					.trainingProgram_title {
						width: 100%;
						height: 150px;
						background: url(../../assets/img/copy.png) no-repeat top;
						background-size: 100%;
						position: relative;
						cursor: pointer;
						overflow: hidden;
					}

					.trainingProgram_center {
						padding: 15px;

						.title {
							width: 100%;
							word-break: keep-all;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							margin: 0;
							display: inline-block;
							color: #333333;
							font-size: 14px;
							font-family: SourceHanSansSC-regular;
						}

						.signup {
							width: 80px;
							height: 26px;
							border-radius: 13px;
							line-height: 26px;

							background-color: #409EFF;
							color: #fff;
							font-size: 12px;
							text-align: center;
							font-family: Microsoft Yahei;
							cursor: pointer;
						}
					}
				}
			}

		}
	}
</style>
